/** VUEX module for Home **/

export const magicScreenArrayConst = 
   [
    {id: 1, type: 'str' ,column: 'supplierName', heading: 'Supplier Name', sortable: false, visible: true},
    {id: 2, type: 'sp11' ,column: 'sku', heading: 'Sku', sortable: true, visible: true},
    {id: 3, type: 'str' ,column: 'upcNumber', heading: 'UPC', sortable: true, visible: true},
    {id: 4, type: 'str' ,column: 'asinNumber', heading: 'ASIN', sortable: true, visible: true},
    {id: 5, type: 'str' ,column: 'supplierProductName', heading: 'Supplier Product Name', sortable: true, visible: true},
    {id: 6, type: 'sp9' ,column: 'amazonName', heading: 'Amazon Product Name', sortable: true, visible: true},
    {id: 7, type: 'sp12' ,column: 'referenceSalesRank', heading: 'BSR', sortable: true, visible: true},
    {id: 8, type: 'sp1' ,column: 'referencePrice', heading: 'Amazon Price', sortable: true, visible: true},
    {id: 9, type: 'sp2' ,column: 'prepCost', heading: 'Prep Cost', sortable: true, visible: true},
    {id: 10, type: 'sp3' ,column: 'referralFeeValue', heading: 'Referral Fee', sortable: true, visible: true},
    {id: 11, type: 'sp4' ,column: 'shippingCost', heading: 'Shipping Cost', sortable: true, visible: true},
    {id: 12, type: 'dec' ,column: 'fbaFee', heading: 'Fba Fee', sortable: true, visible: true},
    {id: 13, type: 'sp5' ,column: 'breakEven', heading: 'Break Even', sortable: true, visible: true},
    {id: 14, type: 'int' ,column: 'packSize', heading: 'Pack Size', sortable: true, visible: true},
    {id: 15, type: 'int' ,column: 'casePack', heading: 'Case Pack', sortable: true, visible: true},
    {id: 16, type: 'sp6' ,column: 'unitPrice', heading: 'Unit Price', sortable: true, visible: true},
    {id: 17, type: 'sp7' ,column: 'buyCost', heading: 'Buy Cost', sortable: true, visible: true},
    {id: 18, type: 'sp10' ,column: 'asinCost', heading: 'Total Buy Cost', sortable: true, visible: true},
    {id: 19, type: 'sp8' ,column: 'profitLoss', heading: 'Profit/Loss', sortable: true, visible: true},
    {id: 20, type: 'dat' ,column: 'reviewDate', heading: 'Review Date', sortable: true, visible: false},
    {id: 21, type: 'fra' ,column: 'amazonOos90d', heading: 'Amazon Oos 90d', sortable: true, visible: true},
    {id: 22, type: 'str' ,column: 'brandName', heading: 'Brand Name', sortable: true, visible: false},
    {id: 23, type: 'str' ,column: 'categoryName', heading: 'Category Name', sortable: true, visible: false},
    // {id: 21, type: 'str' ,column: 'tierName', heading: 'TierName', sortable: true, visible: false},
    {id: 24, type: 'int' ,column: 'estimatedMonthlySales', heading: 'Sales', sortable: true, visible: false},
    {id: 25, type: 'int' ,column: 'estimatedMonthlySalesShare', heading: 'Share', sortable: true, visible: false},
    {id: 26, type: 'int' ,column: 'fbaSellerCount', heading: 'Seller Count', sortable: true, visible: false},
    {id: 27, type: 'dec' ,column: 'variationReviewPercentage', heading: 'Review %', sortable: true, visible: false},
    {id: 28, type: 'fra' ,column: 'margin', heading: 'Margin %', sortable: true, visible: true},
    {id: 29, type: 'fra' ,column: 'roi', heading: 'Roi %', sortable: true, visible: true},
    
    
    
    // {id: 13, type: 'bol' ,column: 'isSpecialPrice', heading: 'Is Special Price', visible: false},
    // {id: 13, type: 'dec' ,column: 'unitPrice', heading: 'Unit Price', visible: false},
    // {id: 14, type: 'dec' ,column: 'unitPriceSpecial', heading: 'Unit Price Special', visible: false},
    // {id: 15, type: 'dec' ,column: 'buyCost', heading: 'Buy Cost', visible: false},
    // {id: 16, type: 'dec' ,column: 'buyCostSpecial', heading: 'Buy Cost Special', visible: false},
    // {id: 18,  type: 'dec' ,column: 'prepFactor', heading: 'Prep Factor', visible: false},
    // {id: 19,  type: 'dec' ,column: 'shippingFactor', heading: 'Shipping Factor', visible: false},
    // {id: 20,  type: 'dec' ,column: 'length', heading: 'Length', visible: false},
    // {id: 21,  type: 'dec' ,column: 'width', heading: 'Width', visible: false},
    // {id: 22,  type: 'dec' ,column: 'height', heading: 'Height', visible: false},
    // {id: 23,  type: 'dec' ,column: 'weight', heading: 'Weight', visible: false},
    // {id: 24,  type: 'dec' ,column: 'keepaFbaFee', heading: 'Fba Fee', visible: false},
    // {id: 25,  type: 'int' ,column: 'asinUpcId', heading: 'AsinUpcId', visible: false},
    // {id: 26,  type: 'int' ,column: 'supplierId', heading: 'SupplierId', visible: false},
    // {id: 27, type: 'int' ,column: 'supplierProductId', heading: 'SupplierProductId', visible: false},
    // {id: 31, type: 'dat' ,column: 'asinLastUpdate', heading: 'Asin Last Update', visible: false},
    // {id: 32, type: 'bol' ,column: 'isMeltable', heading: 'Meltable', visible: false},
    // {id: 33, type: 'bol' ,column: 'isHazmat', heading: 'Hazmat', visible: false},
    // {id: 34, type: 'bol' ,column: 'isGlass', heading: 'Glass', visible: false},
    // {id: 35, type: 'bol' ,column: 'isPesticide', heading: 'Pesticide', visible: false},
    // {id: 36, type: 'bol' ,column: 'needsApproval', heading: 'Needs Approval', visible: false},
    // {id: 37, type: 'int' ,column: 'brandId', heading: 'Brand Id', visible: false},
    // {id: 39, type: 'int' ,column: 'categoryId', heading: 'Category Id', visible: false},
    // {id: 44, type: 'int' ,column: 'tierId', heading: 'Tier Id', visible: false},
    // {id: 46, type: 'dec' ,column: 'shippingWeight', heading: 'Shipping Weight', visible: false},
    // {id: 47, type: 'dec' ,column: 'shippingWeightPrepToFba', heading: 'Shipping Weight Prep To Fba', visible: false},
    // {id: 48, type: 'int' ,column: 'tierId', heading: 'Tier Id', visible: false},
    // {id: 49, type: 'dmy' ,column: 'beginDate', heading: 'Begin Date', visible: false},
    // {id: 50, type: 'dmy' ,column: 'endDate', heading: 'End Date', visible: false},
    // {id: 51, type: 'dec' ,column: 'referralFeePercentage', heading: 'Referral Fee Percentage', visible: false},
  ];

// export const magicScreenSortableArrayConst = [
//   'sku', 'upcNumber', 'asinNumber', 'supplierProductName', 'amazonName', 'salesRank', 'amazonPrice',
//   'packSize', 'reviewDate', 'amazonOos90d', 'brandName', 'categoryName', 
//   'estimatedMonthlySales', 'estimatedMonthlySalesShare', 'fbaSellerCount', 'variationReviewPercentage',
// ]

export const magicScreenCustomFiltersArrayConst =
   ['reviewed', 'priceReference', 'buyingDate', 'salesRankReference', 'bsrBegin', 'bsrEnd',
    'profitMin', 'profitMax', 'supplierId', 'isMeltable', 'isGlass', 'isHazmat', 'isPesticide',
    'needsApproval', 'categoryId', 'brandName', 'tierTypeId', 'estimatedMonthlySales', 'estimatedMonthlySalesShare',
    'fbaSellerCount', 'ipRisk', 'hotsheet', 'packSize', 'fileId', 'marginMin', 'marginMax', 'roiMin', 'roiMax',
    'amzOosMin', 'amzOosMax'];


export const magicScreenCustomFiltersObjectConst =
   { reviewed : true, priceReference : 2, buyingDate : '2021-01-01', salesRankReference : 2, 
           bsrBegin : 0, bsrEnd : 100000, profitMin : 3, profitMax : '', supplierId : '',
           isMeltable : false, isGlass : false, isHazmat : false, isPesticide : false, needsApproval : false, 
           categoryId : '', brandName : '', tierTypeId: '', estimatedMonthlySales: '', 
           estimatedMonthlySalesShare: '', fbaSellerCount: '', ipRisk: false, hotsheet: false, 
           packSize: '', fileId: '', marginMin: '', marginMax: '', roiMin: '', roiMax: '', 
           amzOosMin : '', amzOosMax : '' }



// Sortable Calcs: 'prepCost', 'referralFee', 'shippingCost', 'fbaFee', 'breakEven', 
//        'unitPriceCalc', 'buyCostCalc', 'profitLoss', 'tierName',

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    magicScreenArray: magicScreenArrayConst,
    magicScreenCustomFiltersArray: magicScreenCustomFiltersArrayConst,
    magicScreenCustomFiltersObject: magicScreenCustomFiltersObjectConst
    // magicScreenSortableArray: magicScreenSortableArrayConst
  },
  // -----------------------------------------------------------------
  getters: {
    magicScreenArray: (state) => {
      return state.magicScreenArray
    },
    magicScreenSortableArray: (state) => {
      var arr = state.magicScreenArray.filter(q => q.sortable == true)
      return arr.map(q => q.column)
    },
    magicScreenArrayEnabled: (state) => {
      return state.magicScreenArray.filter(q => q.visible == true)
    },
    magicScreenColumns: (state, getters) => {
      return getters.magicScreenArrayEnabled.map(q => q.column)
    },
    magicScreenHeadings: (state, getters) => {
      var result = getters.magicScreenArrayEnabled.reduce(function(map, obj) {
        map[obj.column] = obj.heading;
        return map;
      }, {});
      return result;
    },
    magicScreenCustomFiltersArray: (state) => {
      return state.magicScreenCustomFiltersArray
    },
    magicScreenCustomFiltersObject: (state) => {
      return state.magicScreenCustomFiltersObject
    },
  },
  // -----------------------------------------------------------------
  mutations: {
    setMagicScreenArray: (state, data) => {
      state.magicScreenArray = data
    },
    setMagicScreenCustomFiltersObject: (state, data) => {
      state.magicScreenCustomFiltersObject = data
    },
  },
  // -----------------------------------------------------------------
  actions: {
    setMagicScreenArray ({ commit }, state) {
      commit('setMagicScreenArray', state)
    },
  }
}
