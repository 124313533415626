// Application
export const APP_HOME_URL = '/'

// Authentication
export const AUTH_LOGIN_URL = '/api/auth/login/'
export const AUTH_REGISTER_URL = '/api/auth/register'
export const AUTH_RESET_PASSWORD_URL = '/api/auth/reset'

// Suppliers
export const SUPPLIER_URL = '/api/suppliers'
export const SUPPLIER_DETAIL_URL = '/api/suppliers/:id'
export const SUPPLIER_NOTE = '/api/suppliers/notes/:id'
export const SUPPLIER_CONTACT = '/api/suppliers/contacts/:id'
export const SUPPLIER_ADDRESS = '/api/suppliers/addresses/:id'
export const SUPPLIER_NAMES = '/api/suppliers/names'
export const SUPPLIERS_WITH_PRODUCTS = '/api/suppliers/with-products'
export const SUPPLIER_BRANDS = '/api/suppliers/brands?search=:query'

// User
export const USER_CHANGE_PASSWORD_URL = '/users/change'
export const USER_REFRESH_TOKEN_URL = '/users/refresh'
export const USER_SUBSCRIPTIONS_URL = '/users/subcriptions'

// ZipCode
export const ZIPCODE_URL = '/api/zipcode/:id'

// File Types
export const FILETYPE_URL = '/api/file/filetypes'
export const DOCUMENTTYPE_URL = '/api/file/documenttypes'
export const FILE_URL = '/api/file/:id'
export const DELETE_FILE_URL = '/api/file/supplier/:id'
export const FILE_DOWNLOAD_URL = '/api/file/download/:id'
export const FILE_NAMES_FROM_SUPPLIER = '/api/file/filenames/:id'

// Keepas
export const KEEPA_BREAK_EVEN_LIST_URL = '/api/keepa/break-even-list'
export const KEEPA_BREAK_EVEN_GET_URL = '/api/keepa/break-even/:id'
export const KEEPA_FILE_LIST_URL = '/api/file/keepa'
export const KEEPA_FILE_UPLOAD_URL = '/api/keepa/upload'
export const KEEPA_REPROCESS_URL = '/api/keepa/reprocess/:id'

// Supplier Product
export const SUPPLIER_PRODUCT_FILE_LIST_URL = '/api/file/supplier-product'
export const SUPPLIER_PRODUCT_LOG_FILE_DOWNLOAD_URL = '/api/file/download-log/:id'
export const SUPPLIER_PRODUCT_FILE_UPLOAD_URL = '/api/file/supplier'
export const SUPPLIER_PRODUCT_LOG_UPCS_DOWNLOAD_URL = '/api/file/download-upcs/:id'
export const SUPPLIER_PRODUCT_FILE_DELETE_URL = '/api/file/supplier-product/:id'
export const SUPPLIER_PRODUCT_FILE_CONTENT_URL = '/api/productlist/file-content'

// AsinDataReview
export const ASIN_DATA_REVIEW_GET_ITEM = '/api/asindatareview/get/:supplierProductId/:asinNumber'
export const ASIN_UPC_MISMATCH_SET = '/api/asindatareview/asinupc-mismatch'
export const ASIN_DATA_REVIEW_SAVE = '/api/asindatareview/save'

// Magic Query
export const MAGIC_QUERY_LIST_URL = '/api/magicquery'