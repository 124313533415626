import Layout2 from '@/layout/Layout2'

export default [
  {
    path: '/keepa',
    component: Layout2,
    children: [
      {
        path: '',
        component: () => import('@/pages/keepa/files/Files'),
        meta: {
          public: false // Allow access to only loggedin user.
        }
      },
      // {
      //   path: 'break-even',
      //   component: () => import('@/pages/keepa/breakEven/List'),
      //   meta: {
      //     public: false // Allow access to only loggedin user.
      //   }
      // }
    ]
  }
]
