<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo bg-primary">
        <!-- Logo -->
        <app-logo height="50" width="50" fill="fill:#ffffff;"></app-logo>
        <!-- / Logo -->
      </span>
      <router-link
        to="/dashboard"
        class="app-brand-text demo sidenav-text font-weight-normal ml-2"
        >{{ appName }}</router-link
      >
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
    
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      
      <sidenav-router-link icon="ion ion-md-speedometer" to="/dashboard" :exact="true">Dashboard</sidenav-router-link>
      <sidenav-divider class="mb-1"/>

      <sidenav-menu icon="ion ion-md-cube" :active="isMenuActive('/suppliers')" :open="isMenuOpen('/suppliers')">
        <template slot="link-text">Suppliers</template>
        <sidenav-router-link to="/suppliers" :exact="true">List</sidenav-router-link>
        <sidenav-router-link to="/suppliers/new" :exact="true">New</sidenav-router-link>
        <sidenav-router-link to="/suppliers/price-lists" :exact="true">Price Lists</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu v-if="user.role != 'VA'" icon="ion ion-md-add-circle" :active="isMenuActive('/keepa')" :open="isMenuOpen('/keepa')">
        <template slot="link-text">Keepa</template>
        <sidenav-router-link to="/keepa" :exact="true">Files</sidenav-router-link>
      </sidenav-menu>

      <sidenav-router-link v-if="user.role != 'VA'" icon="fas fa-magic" to="/magic-screen" :exact="true">Magic Screen</sidenav-router-link>

      <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
      <sidenav-router-link icon="ion ion-md-quote" to="/changelog" :exact="true">ChangeLog</sidenav-router-link>
      <sidenav-router-link-disabled to="#" :exact="false">Version 0.7.3 (2021-07-06)</sidenav-router-link-disabled>

      <!-- <sidenav-router-link icon="ion ion-md-cube" to="/suppliers" :exact="true">Suppliers</sidenav-router-link> -->
    </div>
  </sidenav>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
  SidenavRouterLinkDisabled
} from '@/vendor/libs/sidenav'

export default {
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    SidenavRouterLinkDisabled,
    appLogo: () => import('@/components/shared/Logo')
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    ...mapGetters('AUTH', ['user']),
    curClasses () {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 ||
          bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
      )
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
