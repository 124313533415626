import { DataApiService } from '@/services/apis/data.api.service'
import { SUPPLIER_URL, SUPPLIER_DETAIL_URL, SUPPLIER_NOTE, SUPPLIER_CONTACT, 
         SUPPLIER_ADDRESS, SUPPLIER_NAMES, SUPPLIERS_WITH_PRODUCTS, SUPPLIER_BRANDS } from '@/services/shared/endpoint.types'
import ConnectionError from '@/services/shared/connection.error'

const SupplierService = {
  suppliers: async () => {
    try {
      return await DataApiService.get(SUPPLIER_URL)
    } catch (error) {
      ConnectionError(error)
    }
  },
  supplierNames: async () => {
    try {
      return await DataApiService.get(SUPPLIER_NAMES)
    } catch (error) {
      ConnectionError(error)
    }
  },
  suppliersWithProducts: async () => {
    try {
      return await DataApiService.get(SUPPLIERS_WITH_PRODUCTS)
    } catch (error) {
      ConnectionError(error)
    }
  },
  supplier: async (id) => {
    try {
      return await DataApiService.get(SUPPLIER_DETAIL_URL.replace(':id', id))
    } catch (error) {
      ConnectionError(error)
    }
  },
  supplierBrands: async (query) => {
    try {
      return await DataApiService.get(SUPPLIER_BRANDS.replace(':query', query))
    } catch (error) {
      ConnectionError(error)
    }
  },
  addSupplier: async (obj) => {
    try {
      return await DataApiService.post(SUPPLIER_URL, obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  updateSupplier: async (supplierId, obj) => {
    try {
      return await DataApiService.put(SUPPLIER_DETAIL_URL.replace(':id', supplierId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },

  deleteSupplier: async (supplierId) => {
    try {
      return await DataApiService.delete(SUPPLIER_DETAIL_URL.replace(':id', supplierId))
    } catch (error) {
      ConnectionError(error)
    }
  },

  addSupplierNote: async (supplierId, obj) => {
    try {
      return await DataApiService.post(SUPPLIER_NOTE.replace(':id', supplierId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  updateSupplierNote: async (supplierNoteId, obj) => {
    try {
      return await DataApiService.put(SUPPLIER_NOTE.replace(':id', supplierNoteId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  deleteSupplierNote: async (supplierNoteId) => {
    try {
      return await DataApiService.delete(SUPPLIER_NOTE.replace(':id', supplierNoteId))
    } catch (error) {
      ConnectionError(error)
    }
  },

  addSupplierContact: async (supplierId, obj) => {
    try {
      return await DataApiService.post(SUPPLIER_CONTACT.replace(':id', supplierId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  updateSupplierContact: async (supplieContactId, obj) => {
    try {
      return await DataApiService.put(SUPPLIER_CONTACT.replace(':id', supplieContactId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  deleteSupplierContact: async (supplierContactId) => {
    try {
      return await DataApiService.delete(SUPPLIER_CONTACT.replace(':id', supplierContactId))
    } catch (error) {
      ConnectionError(error)
    }
  },

  addSupplierAddress: async (supplierId, obj) => {
    try {
      return await DataApiService.post(SUPPLIER_ADDRESS.replace(':id', supplierId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  updateSupplierAddress: async (supplierAddressId, obj) => {
    try {
      return await DataApiService.put(SUPPLIER_ADDRESS.replace(':id', supplierAddressId), obj)
    } catch (error) {
      ConnectionError(error)
    }
  },
  deleteSupplierAddress: async (supplierAddressId) => {
    try {
      return await DataApiService.delete(SUPPLIER_ADDRESS.replace(':id', supplierAddressId))
    } catch (error) {
      ConnectionError(error)
    }
  }
}

export default SupplierService
